import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import styled from '@emotion/styled';
import FunctinosTemplate from 'components/Templates/FunctinosTemplate';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface environmentAnalysisProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgEnv1: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgEnv2: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const environmentAnalysis = ({ data, location }: environmentAnalysisProps) => {
  const title = 'VODA | 기능 | 사용 환경 분석';
  const desc =
    '방문한 사용자의 환경을 세세하게 분석해 사용자의 관점을 이해하도록 도와드립니다';
  return (
    <Component>
      <Helmet>
        <title>VODA | 기능 | 사용 환경 분석</title>
        <meta
          name="description"
          content="방문한 사용자의 환경을 세세하게 분석해 사용자의 관점을 이해하도록 도와드립니다"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/functions/environmentAnalysis>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <FunctinosTemplate
        location={location}
        title={'시작을\\n헤아려 VODA'}
        header={{
          main: '방문한 사용자의 환경을\\n세세하게 분석해보세요.\\n사용자의 관점을 이해할 수 있어요',
          sub: '시작을 헤아려 VODA',
        }}
        isAIvory={false}
        functionData={[
          {
            IllustImg: data.imgEnv1.childImageSharp.fluid,
            descData: {
              title: '방문한 사용자의\\n접속 환경을\\n분석해보세요 (수정 필요)',
              list: ['1. 국내 접속 도시 분석', '2. 국외 접속 도시 분석'],
            },
            engTitle: 'ENVIRONMENT',
            korTitle: '사용 환경 분석 (수정 필요)',
            isBackGround: true,
            isDescLeft: false,
            width: 648,
            key: '0',
          },
          {
            IllustImg: data.imgEnv2.childImageSharp.fluid,
            descData: {
              title: '방문한 사용자의\\n접속 환경을\\n분석해보세요',
              list: [
                '1. 접속 디바이스 별 방문비율 분석',
                '2. 접속 운영체제 별 방문비율 분석',
                '3. 접속 브라우저 별 방문비율 분석',
                '4. 접속 사용자 별 해상도 비율 분석',
              ],
            },
            engTitle: 'ENVIRONMENT',
            korTitle: '사용 환경 분석',
            isBackGround: false,
            isDescLeft: true,
            width: 648,
            key: '1',
          },
        ]}
        img={data.topFunctionImg.childImageSharp.fluid}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
      />
    </Component>
  );
};

export default environmentAnalysis;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-function@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgEnv1: file(name: { eq: "img-global-region" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgEnv2: file(name: { eq: "img-environment" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
